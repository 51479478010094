import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import QueryString from 'query-string';
import Input from '/client/app/components/common/inputs/input/input';
import '/client/app/styles/text.scss';
import '/client/app/styles/controls.scss';
import { requestPasswordResetLink, resetPassword } from '/client/app/api/backend';

import './resetPassword.scss';

export default class ResetPasswordView extends React.Component {
	constructor(props) {
		super(props);

		this.sendResetLink = this.sendResetLink.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.getRequestLinkForm = this.getRequestLinkForm.bind(this);
		this.getChangePasswordForm = this.getChangePasswordForm.bind(this);
		this.onEmailInput = this.onEmailInput.bind(this);
		this.onPasswordConfirmationInput = this.onPasswordConfirmationInput.bind(this);
		this.onPasswordInput = this.onPasswordInput.bind(this);

		const { location } = this.props;
		const { email } = QueryString.parse(location.search);

		this.state = {
			email: email || '',
			password: '',
			passwordConfirmation: '',
			error: '',
			requestReceived: false,
			inputsDisabled: false,
			submitButtonDisabled: false,
			passwordChanged: false,
		};
	}

	componentDidMount() {
		document.title = 'pfyt - reset password';
	}

	componentWillUnmount() {
		document.title = 'pfyt';
	}

	onEmailInput(e) {
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			email: value,
		}));
		return true;
	}

	onPasswordInput(e) {
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			password: value,
		}));
		return true;
	}

	onPasswordConfirmationInput(e) {
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			passwordConfirmation: value,
		}));
		return true;
	}

	async changePassword(e) {
		const { location } = this.props;
		const { password } = this.state;
		e.preventDefault();
		const { uuid, email } = QueryString.parse(location.search);
		this.setState((prevState) => ({
			...prevState,
			inputsDisabled: true,
		}));
		const result = await resetPassword(email, uuid, password);
		this.setState((prevState) => ({
			...prevState,
			inputsDisabled: false,
		}));
		if (result === 'success') {
			this.setState((prevState) => ({
				...prevState,
				passwordChanged: true,
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: result,
				passwordChanged: false,
			}));
		}
		return false;
	}

	async sendResetLink(e) {
		e.preventDefault();

		const { inputsDisabled, email } = this.state;

		if (inputsDisabled) return false;

		if (email && email.length > 0) {
			this.setState((prevState) => ({
				...prevState,
				inputsDisabled: true,
			}));
			const result = await requestPasswordResetLink(email);
			this.setState((prevState) => ({
				...prevState,
				inputsDisabled: false,
			}));
			if (result === 'success') {
				this.setState((prevState) => ({
					...prevState,
					requestReceived: true,
					error: '',
				}));
			} else {
				this.setState((prevState) => ({
					...prevState,
					error: result,
					requestReceived: false,
				}));
			}
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: 'email address is required',
				requestReceived: false,
			}));
		}
		return false;
	}

	getRequestLinkForm() {
		const { inputsDisabled, email, error, requestReceived } = this.state;

		return (
			<form onSubmit={this.sendResetLink}>
				<label htmlFor="email">email</label>
				<br />
				<Input
					type="text"
					disabled={inputsDisabled}
					placeholder="email address"
					onInput={this.onEmailInput}
					value={email}
					name="email"
					id="email"
					className="resetPasswordFormField"
					uid="reset_password_email"
				/>
				<br />
				<input
					type="submit"
					className="standardButton resetPasswordButton"
					disabled={inputsDisabled || validator.isEmail(email) === false}
					value="send password reset link"
				/>
				<br />
				<div className="resetPasswordError">{error}</div>
				{requestReceived ? (
					<div className="resetPasswordSuccess">your password reset link request has been received</div>
				) : (
					[]
				)}
			</form>
		);
	}

	getChangePasswordForm() {
		const { inputsDisabled, passwordChanged, error } = this.state;

		return (
			<form onSubmit={this.changePassword}>
				<label htmlFor="password">new password</label>
				<br />
				<Input
					type="password"
					id="password"
					disabled={inputsDisabled}
					placeholder="enter new password"
					onInput={this.onPasswordInput}
					name="password"
					uid="reset_password_password"
					className="resetPasswordFormField"
				/>
				<br />
				<label htmlFor="password2">confirm new password</label>
				<br />
				<Input
					type="password"
					id="password2"
					disabled={inputsDisabled}
					placeholder="confirm new password"
					onInput={this.onPasswordConfirmationInput}
					name="password2"
					uid="reset_password_password2"
					className="resetPasswordFormField"
				/>
				<br />
				<input
					type="submit"
					className={`standardButton resetPasswordButton${inputsDisabled ? ' disabled' : ''}`}
					value="change password"
				/>
				<br />
				<div className="resetPasswordError">{error}</div>
				{passwordChanged ? <div className="resetPasswordSuccess">your password has been changed</div> : []}
			</form>
		);
	}

	render() {
		const { location } = this.props;
		const { uuid, email } = QueryString.parse(location.search);
		return (
			<>
				<div className="mainTitle">reset password</div>
				<div className="resetPasswordForm">
					<p>
						If you are unable to remember your password you can recover your account by resetting it.
						<br />
						If the email address you provide is associated with a valid account a password reset link will be received.
					</p>
					{uuid && email ? this.getChangePasswordForm() : this.getRequestLinkForm()}
				</div>
			</>
		);
	}
}

ResetPasswordView.propTypes = {
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};
